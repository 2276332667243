
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class ReviewUnknown extends Vue {
    @Prop({required: true}) public showSidebar!: boolean;

    public closeSidebar() {
        this.$emit('close-sidebar');
    }
}
