
import { dispatchSubmitTestimonial } from '@/store/testimonials/actions';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class ReviewUser extends Vue {
    @Prop({required: true}) public showSidebar!: boolean;
    @Prop({required: true}) public product_id!: number;
    public mark: number = 0;
    public text: string = '';
    public loadingState: any = {
        submit: false,
    };

    public async handleSubmit() {
        if (this.mark > 0 && this.text) {
            this.loadingState.submit = true;
            const result = await dispatchSubmitTestimonial(
                this.$store, {product: this.product_id, mark: this.mark, text: this.text},
            );
            if (result) {
                this.$emit('update-reviews');
                this.$toast.success(this.$t('Отзыв сохранен').toString());
                this.closeSidebar();
            } else {
                this.$toast.error(this.$t('Ошибка').toString());
            }
            this.loadingState.submit = false;
        } else {
            this.$toast.error(this.$t('Оцените продукт и напишите отзыв').toString());
        }
    }

    public closeSidebar() {
        this.mark = 0;
        this.text = '';
        this.$emit('close-sidebar');
    }
}
