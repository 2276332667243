
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class Review extends Vue {
    public productRoute: string = '';

    public mounted() {
        this.productRoute = window.location.href;
    }
}
